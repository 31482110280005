import React, { useEffect, useState, useMemo, useCallback } from 'react';
// import styled from 'styled-components';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import 'react-calendar/dist/Calendar.css';
import { Calendar } from 'react-calendar';
import { FaArrowRight, FaEdit, FaEye, FaPlus, FaTrash } from 'react-icons/fa';
import { CalendarLocal } from '../components/CalendarLocal';
import { ModalAddEvent } from '../components/modalAddEvent/index';
import { useEvent } from './../../hooks/useEvent';
import Loading from 'react-loading';
import SearchBar from '../components/searchBar';
import { apiAp } from './../../services/apiAmapá';
import { api } from './../../services/api';
import axios from 'axios';
import { ModalNews } from '../components/modalNews';
import { toast } from 'react-toastify';
import { Buffer } from 'buffer';
import { ModalEditNews } from '../components/modalEditNews';
import { ModalWarning } from '../components/modalWarning';

interface newsAmapa {
  id: string;
  nt_id_cat: string;
  nt_slug: string;
  nt_data_pub: string;
  nt_titulo: string;
  nt_chamada: string;
  nt_foto: string;
  nt_legenda: string;
  nt_texto: string;
}

interface newsLocal {
  id: string;
  idApiNews: string;
  publishDate: string;
  title: string;
  subtitle: string;
  image: string;
  caption: string;
  description: string;
}

export function News(): JSX.Element {
  const [inputAdded, setInputAdded] = useState('');
  const [inputNotAdded, setInputNotAdded] = useState('');
  const [newsListDefault, setNewsListDefault] = useState<newsAmapa[]>([]);
  const [newsList, setNewsList] = useState<newsAmapa[]>([]);

  const [newsListLocalDefault, setNewsListLocalDefault] = useState<newsLocal[]>([]);
  const [newsListLocal, setNewsListLocal] = useState<newsLocal[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingLocal, setLoadingLocal] = useState(false);

  const [loadingSearch, setLoadingSearch] = useState(false);
  const [septemberDays, setSeptemberDays] = useState<Date[]>([]);
  const [isOpenModalNews, setIsOpenModalNews] = useState(false);
  const [isOpenModalNewsLocal, setIsOpenModalNewsLocal] = useState(false);

  const [selected, setSelected] = useState<newsAmapa>();

  const [selectedForDelete, setSelectedForDelete] = useState<newsLocal>();
  const [selectedForAdd, setSelectedForAdd] = useState<newsAmapa>();

  const [selectedForDeleteAux, setSelectedForDeleteAux] = useState<newsLocal>();
  const [selectedForAddAux, setSelectedForAddAux] = useState<newsAmapa>();

  const [selectedNewsLocal, setSelectedNewsLocal] = useState<newsLocal>();
  const [openWarning, setOpenWarning] = useState(false);
  function handleOpenWarning(): void {
    setOpenWarning(!openWarning);
    if (!openWarning) {
      setSelectedForAdd(null);
    }
  }

  const [openWarningDeleteNews, setOpenWarningDeleteNews] = useState(false);
  function handleOpenWarningDeleteNews(): void {
    setOpenWarningDeleteNews(!openWarningDeleteNews);
    if (!openWarningDeleteNews) {
      setSelectedForDelete(null);
    }
  }
  function localNewsHandleOpenModal(item: newsLocal): void {
    setSelectedNewsLocal(item);
    handleIsOpenModalNewsLocal();
  }

  useEffect(() => {
    if (selectedForDeleteAux) {
      handleOpenWarningDeleteNews();
    }
  }, [selectedForDeleteAux]);
  useEffect(() => {
    if (selectedForAdd) {
      handleOpenWarning();
    }
  }, [selectedForAdd]);

  function localHandleOpenModal(item: newsAmapa): void {
    setSelected(item);
    handleIsOpenModalNews();
  }
  // useEffect(() => {
  //   handleIsOpenModalRound();
  // }, [selected]);

  function handleIsOpenModalNewsLocal(): void {
    setIsOpenModalNewsLocal(!isOpenModalNewsLocal);
  }
  function handleIsOpenModalNews(): void {
    setIsOpenModalNews(!isOpenModalNews);
  }
  useEffect(() => {
    void getAllNews();
    void getAllNewsLocal();
  }, []);

  const getAllNews = useCallback(async () => {
    setLoading(true);
    try {
      const teste = await fetch('https://amapa.gov.br/api_expofeira.php');
      const data = await teste.json();
      // const response = await apiAp.get('api_expofeira.php', {
      //   headers: { 'Access-Control-Allow-Origin': '*' },
      // });

      setNewsListDefault(data);
      setNewsList(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error, 'teste');
    }
  }, []);

  const getAllNewsLocal = useCallback(async () => {
    setLoadingLocal(true);
    try {
      const response = await api.get('news');

      setNewsListLocalDefault(response.data);
      setNewsListLocal(response.data);
      setLoadingLocal(false);
    } catch (error) {
      setLoadingLocal(false);
      console.log(error, 'teste');
    }
  }, []);

  function convertToPlain(html): string {
    // Create a new div element
    const tempDivElement = document.createElement('div');

    // Set the HTML content with the given value
    tempDivElement.innerHTML = html;

    // Retrieve the text property of the element
    return tempDivElement.textContent || tempDivElement.innerText || '';
  }

  async function registerNews(): Promise<void> {
    // const r = window.confirm(`Deseja adicionar a notícia: "${selectedForAdd.nt_titulo}" ao app?`);
    // if (!r) return;
    setLoadingLocal(true);
    if (selectedForAddAux.nt_foto) {
      try {
        // const file = await axios.get(
        //   `${process.env.REACT_APP_BASE_URl_AMAPA}img/noticias/${selectedForAddAux.nt_foto}`,
        // );
        const response = await api.post('news', {
          title: selectedForAddAux.nt_titulo,
          subtitle: selectedForAddAux.nt_chamada,
          caption: selectedForAddAux.nt_legenda
            ? selectedForAddAux.nt_legenda
            : 'legenda não informada',
          description: selectedForAddAux.nt_texto,
          image: selectedForAddAux.nt_foto
            ? `${process.env.REACT_APP_BASE_URl_AMAPA}img/noticias/${selectedForAddAux.nt_foto}`
            : '',
          publishDate: new Date(selectedForAddAux.nt_data_pub),
          idApiNews: selectedForAddAux.id,
        });
        // const formData = new FormData();
        // formData.append('title', selectedForAddAux.nt_titulo);
        // formData.append('subtitle', selectedForAddAux.nt_chamada);
        // formData.append('publishDate', new Date(selectedForAddAux.nt_data_pub).toISOString());
        // formData.append('caption', selectedForAddAux.nt_legenda);
        // formData.append('description', convertToPlain(selectedForAddAux.nt_texto));
        // formData.append(
        //   'image',
        //   selectedForAddAux.nt_foto ? `${process.env.REACT_APP_BASE_URl_AMAPA}img/noticias/${selectedForAddAux.nt_foto}` : '',
        // );
        // formData.append('idApiNews', selectedForAddAux.id);

        // const file = await fetch(
        //   `${process.env.REACT_APP_BASE_URl_AMAPA}img/noticias/${selectedForAddAux.nt_foto}`,
        //   {
        //     // headers: { 'Content-Type': 'image/jpeg' },
        //   },
        // );

        // const blob = await file.blob();

        // formData.append('file', blob, 'image.jpg');
        // const response = await api.post('news', formData, {
        //   headers: { 'Content-Type': 'multipart/form-data' },
        // });
        handleOpenWarning();
        await getAllNewsLocal();
        setLoadingLocal(false);
        toast('Notícia adicionada com sucesso!', { type: 'success' });
      } catch (error) {
        setLoadingLocal(false);
        handleOpenWarning();
        toast(
          // @ts-expect-error
          error?.response?.data?.error?.response?.error
            ? // @ts-expect-error
              error?.response?.data?.error?.response?.error
            : 'Não foi possível adicionar a notícia!',
          {
            type: 'error',
          },
        );
      }
    } else {
      try {
        const response = await api.post('news', {
          title: selectedForAddAux.nt_titulo,
          subtitle: selectedForAddAux.nt_chamada,
          caption: selectedForAddAux.nt_legenda
            ? selectedForAddAux.nt_legenda
            : 'legenda não informada',
          description: selectedForAddAux.nt_texto,
          image: selectedForAddAux.nt_foto,
          publishDate: new Date(selectedForAddAux.nt_data_pub),
          idApiNews: selectedForAddAux.id,
        });

        await getAllNewsLocal();
        setLoadingLocal(false);
        toast('Notícia adicionada com sucesso!', { type: 'success' });
      } catch (error) {
        setLoadingLocal(false);

        toast(
          // @ts-expect-error
          error?.response?.data?.error?.response?.error
            ? // @ts-expect-error
              error?.response?.data?.error?.response?.error
            : 'Não foi possível adicionar a notícia!',
          {
            type: 'error',
          },
        );
      }
    }
  }

  async function deleteNews(): Promise<void> {
    // const r = window.confirm(`Deseja remover a notícia "${item?.title}"?`);
    // if (!r) return;
    setLoadingLocal(true);
    console.log(selectedForDeleteAux, 'teste');
    try {
      const response = await api.delete(`news/${selectedForDeleteAux.id}`);

      await getAllNewsLocal();
      setLoadingLocal(false);
      handleOpenWarningDeleteNews();
      toast('Notícia removida com sucesso!', { type: 'success' });
    } catch (error) {
      setLoadingLocal(false);
      handleOpenWarningDeleteNews();
      console.log(error, 'teste');
      toast('Não foi possível remover a notícia!', { type: 'error' });
    }
  }

  const updateInputAdded = async (input): Promise<void> => {
    const filtered = newsListLocalDefault.filter((news) => {
      return news.title.toLowerCase().includes(input.toLowerCase());
    });
    setInputAdded(input);
    setNewsListLocal(filtered);
  };

  const updateInputNotAdded = async (input): Promise<void> => {
    const filtered = newsListDefault.filter((news) => {
      return news.nt_titulo.toLowerCase().includes(input.toLowerCase());
    });
    setInputNotAdded(input);
    setNewsList(filtered);
  };

  function handleDelete(item: newsLocal): void {
    setSelectedForDelete(item);
    setSelectedForDeleteAux(item);
  }

  function handleAdd(item: newsAmapa): void {
    setSelectedForAdd(item);
    setSelectedForAddAux(item);
  }

  return (
    <div style={{ width: '100%', backgroundColor: '#FFF', minHeight: '90vh' }}>
      {loading ? (
        <div
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 25 }}
        >
          <Loading width={30} color={'#005675'} type={'spin'} />
        </div>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '50%',
            }}
          >
            <div
              style={{
                width: '100%',
                backgroundColor: '#F7F9F9',
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px solid rgba(0, 0, 0, 0.05)',
              }}
            >
              <h5 style={{ textAlign: 'center' }}>Notícias ExpoFeira</h5>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: 15,
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center',
              }}
            >
              <SearchBar
                placeholder={'Pesquisar'}
                keyword={inputNotAdded}
                setKeyword={updateInputNotAdded}
              />
              <hr />

              {newsList?.length > 0 &&
                newsList?.map((item) => (
                  <div
                    key={item.id}
                    style={{
                      backgroundColor: '#F4F4F4',
                      width: '80%',
                      height: 45,
                      borderRadius: 8,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginBottom: 15,
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        width: '90%',
                        justifyContent: 'start',
                        alignContent: 'center',
                        alignItems: 'center',
                        marginLeft: 10,
                      }}
                    >
                      <span style={{ textAlign: 'justify' }}>
                        {item.nt_titulo.length > 70
                          ? `${item.nt_titulo.slice(0, 70)}...`
                          : item.nt_titulo}
                      </span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        width: '10%',
                        justifyContent: 'start',
                        alignContent: 'center',
                        alignItems: 'center',
                        marginLeft: 10,
                      }}
                    >
                      <div style={{ display: 'flex', width: '50%', justifyContent: 'center' }}>
                        <FaEye
                          onClick={() => {
                            localHandleOpenModal(item);
                          }}
                          color="#0D6EFD"
                          style={{ cursor: 'pointer' }}
                        />
                      </div>
                      <div style={{ display: 'flex', width: '50%', justifyContent: 'center' }}>
                        <FaArrowRight
                          // eslint-disable-next-line @typescript-eslint/no-misused-promises
                          onClick={() => {
                            handleAdd(item);
                          }}
                          color="#0D6EFD"
                          style={{ cursor: 'pointer' }}
                        />
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '50%',
            }}
          >
            <div
              style={{
                width: '100%',
                backgroundColor: '#F7F9F9',
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px solid rgba(0, 0, 0, 0.05)',
              }}
            >
              <h5 style={{ textAlign: 'center' }}>Notícias Adicionadas</h5>
            </div>

            {loadingLocal ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: 25,
                }}
              >
                <Loading width={30} color={'#005675'} type={'spin'} />
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: 15,
                  justifyContent: 'center',
                  alignContent: 'center',
                  alignItems: 'center',
                }}
              >
                <SearchBar
                  placeholder={'Pesquisar'}
                  keyword={inputAdded}
                  setKeyword={updateInputAdded}
                />
                <hr />

                {newsListLocal?.length > 0 &&
                  newsListLocal?.map((item) => (
                    <div
                      key={item.id}
                      style={{
                        backgroundColor: '#F4F4F4',
                        width: '80%',
                        height: 40,
                        borderRadius: 8,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: 15,
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          width: '90%',
                          justifyContent: 'start',
                          alignContent: 'center',
                          alignItems: 'center',
                          marginLeft: 10,
                        }}
                      >
                        <span style={{ textAlign: 'justify' }}>
                          {item?.title?.length > 70
                            ? `${item?.title?.slice(0, 70)}...`
                            : item?.title}
                        </span>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          width: '10%',
                          justifyContent: 'start',
                          alignContent: 'center',
                          alignItems: 'center',
                          marginLeft: 10,
                        }}
                      >
                        <div style={{ display: 'flex', width: '50%', justifyContent: 'center' }}>
                          <FaEdit
                            onClick={() => {
                              localNewsHandleOpenModal(item);
                            }}
                            color="#0D6EFD"
                            style={{ cursor: 'pointer' }}
                          />
                        </div>
                        <div style={{ display: 'flex', width: '50%', justifyContent: 'center' }}>
                          <FaTrash
                            color="#DC3545"
                            // eslint-disable-next-line @typescript-eslint/no-misused-promises
                            onClick={() => {
                              handleDelete(item);
                            }}
                            style={{ cursor: 'pointer' }}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
      )}

      <ModalNews isOpen={isOpenModalNews} handleIsOpen={handleIsOpenModalNews} item={selected} />
      <ModalEditNews
        isOpen={isOpenModalNewsLocal}
        handleIsOpen={handleIsOpenModalNewsLocal}
        item={selectedNewsLocal}
        getAllLocal={getAllNewsLocal}
      />
      <ModalWarning
        action={() => {
          deleteNews()
            .then(() => {})
            .catch(() => {});
        }}
        title={'Deseja remover a notícia?'}
        handleIsOpen={handleOpenWarningDeleteNews}
        isOpen={openWarningDeleteNews}
      />
      <ModalWarning
        action={() => {
          registerNews()
            .then(() => {})
            .catch(() => {});
        }}
        title={'Deseja adicionar a notícia?'}
        handleIsOpen={handleOpenWarning}
        isOpen={openWarning}
      />
    </div>
  );
}

// const WrapperContainer = styled.div`
//   width: 100%;

//   .content {
//     width: 100%;

//     & div {
//       display: flex;
//     }

//     select:focus {
//       outline: none;
//     }

//     textarea:focus {
//       outline: none;
//     }

//     & input {
//       width: 100%;
//     }

//     & input:focus {
//       outline: none !important;
//     }

//     .inputfile {
//       width: 0.1px;
//       height: 0.1px;
//       opacity: 0;
//       overflow: hidden;
//       position: absolute;
//       z-index: -1;
//     }
//     .inputfile:focus + label {
//       outline: 1px dotted #000;
//       outline: -webkit-focus-ring-color auto 5px;
//     }
//     .inputfile + label * {
//       pointer-events: none;
//     }
//   }
// `;
